export default [
  {
    path: '/soa',
    name: 'soa',
    component: () => import('@/views/modules/statement-of-applicability/index.vue'),
    // meta: {
    //   roles: ['super'],
    // },
  },
]
